<template>
  <SidePanel header="Advertiser Reporting">
    <template v-slot:content>
      <p>
        Reporting info...
      </p>
    </template>
  </SidePanel>
</template>

<script>
import SidePanel from "@/components/UI/SidePanel";

export default {
  name: "AdvertiserReportingInfoSidePanel",
  components: { SidePanel },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
