<template>
  <q-btn
    no-caps
    unelevated
    icon="sym_r_event"
    text-color="grey-8"
    align="left"
    class="text-weight-regular full-width"
    :style="{
      background: 'rgba(0,0,0,0.05)'
    }"
    style="height: 40px"
  >
    <div
      v-if="customLabel && date"
      class="column text-left q-ml-sm q-py-xs"
      style="margin-top: -4px; line-height: 1.1em;"
    >
      <span style="font-size: 10px" class="ellipsis">{{ customLabel }}</span>
      <p class="q-ma-none text-black ellipsis" style="line-height: 1em">
        {{ buttonLabel }}
      </p>
    </div>
    <div v-else>
      <span class="q-ml-sm ellipsis" :class="{ 'text-black': date }">
        {{ buttonLabel }}
      </span>
    </div>
    <q-popup-proxy
      transition-show="jump-down"
      transition-hide="jump-up"
      :offset="[0, -80]"
      ref="dateSelectorPopup"
    >
      <div class="row" style="z-index: 11">
        <q-date
          :model-value="date"
          @update:model-value="
            val => {
              $emit('update:date', val);
              $refs.dateSelectorPopup.hide();
            }
          "
          mask="YYYY-MM-DD"
          :today-btn="true"
          landscape
        />
      </div>
    </q-popup-proxy>
  </q-btn>
</template>

<script>
export default {
  name: "DateSelector",
  props: {
    date: {
      type: String,
      required: true
    },
    customPlaceholder: {
      type: String,
      default: ""
    },
    customLabel: {
      type: String,
      default: ""
    }
  },
  emits: ["update:date"],
  computed: {
    buttonLabel() {
      return !this.date
        ? this.customPlaceholder.length > 0
          ? this.customPlaceholder
          : this.customLabel.length > 0
          ? this.customLabel
          : "Select Date"
        : this.$dayjs(this.date, "YYYY-MM-DD").format("ll");
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss"></style>
