<template>
  <div class="page-titlebar-container">
    <slot name="above" />
    <div class="page-titlebar shadow-default q-mb-md">
      <div class="q-gutter-x-md">
        <slot name="top" />
      </div>
      <div class="row justify-between items-center" style="min-height: 34px">
        <div v-if="title.length > 0" class="flex items-center">
          <q-icon
            v-if="titleIcon.length > 0"
            :name="titleIcon"
            class="q-mr-md"
            size="sm"
          />
          <div>
            <h5>{{ title }}</h5>
            <p
              v-if="subTitle.length > 0"
              v-html="subTitle"
              class="text-font-secondary"
            ></p>
            <p
              v-else
              class="text-font-secondary"
            >
              <slot name="sub-title" />
            </p>
          </div>
        </div>

        <slot name="title" />

        <div class="q-gutter-x-md">
          <slot name="actions" />
        </div>
      </div>
      <div class="q-gutter-x-md">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageTitlebar",
  props: {
    title: {
      type: String,
      default: ""
    },
    titleIcon: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped lang="scss">
.page-titlebar-container {
  background: #fff;
  margin: (-$space-xl) (-$space-lg) $space-lg;

  position: sticky;
  top: $the-titlebar-height;
  z-index: 2;

  .page-titlebar {
    padding: $space-md $space-lg;
    min-height: 66px;
  }
}
</style>
