<template>
  <q-btn
    no-caps
    unelevated
    :icon="presetsOnly ? '' : 'sym_r_event'"
    :icon-right="presetsOnly ? 'sym_r_arrow_drop_down' : ''"
    text-color="grey-8"
    align="left"
    class="text-weight-regular"
    :color="presetsOnly ? '' : 'gray-light'"
    :style="[presetsOnly ? { padding: '0 !important', 'min-height': '0' } : {}]"
    :class="{ 'q-btn--no-hover': presetsOnly }"
  >
    <template v-if="!presetsOnly">
      <span class="q-ml-sm text-black">{{ selectorButtonLabel }}</span>

      <q-badge
        v-show="selectedState.preset.length > 0"
        color="primary"
        class="q-ml-md"
      >
        {{ selectedState.preset }}
      </q-badge>
    </template>
    <template v-else>
      <span class="q-ml-sm text-black">{{ selectedState.preset }}</span>
    </template>

    <q-popup-proxy
      transition-show="jump-down"
      transition-hide="jump-up"
      :offset="[0, -80]"
      ref="dateRangeSelectorPopup"
      @before-hide="onPopupHide"
    >
      <div class="row">
        <div v-if="!presetsOnly" class="col" :style="{ 'z-index': 11 }">
          <q-date
            v-model="popupState.range"
            range
            mask="YYYY-MM-DD"
            :today-btn="true"
            landscape
          >
            <div class="flex justify-between items-center">
              <q-btn
                flat
                label="Cancel"
                color="subtle"
                @click="
                  () => {
                    cancelPopupChanges();
                  }
                "
              />
              <q-btn
                flat
                label="Update"
                color="primary"
                @click="
                  () => {
                    savePopupChanges();
                  }
                "
              />
            </div>
          </q-date>
        </div>
        <div class="bg-grey-1" style="min-width: 130px">
          <!--<p class="text-grey-6 text-bold q-ml-md q-py-sm">
            Date Presets
          </p>-->
          <Label text="Date Presets" class="q-px-md q-py-sm" />
          <q-list dense>
            <q-item
              v-for="presetLabel in Object.keys(presets)"
              :key="presetLabel"
              :label="presetLabel"
              :clickable="true"
              @click="
                () => {
                  setPopupPreset(presetLabel);
                  savePopupChanges();
                }
              "
              :focused="presetLabel === popupState.preset"
              :active="presetLabel === popupState.preset"
            >
              <q-item-section>
                <span style="font-size: 90%">{{ presetLabel }}</span>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </q-popup-proxy>
  </q-btn>
</template>

<script>
import Label from "@/components/UI/Label";
import * as dayjs from "dayjs";

export default {
  name: "DateRangeSelector",
  components: { Label },
  props: {
    // TODO: ADD prop "saveAsUrlParams" to allow this component to push the start/end date and date preset params dirctly to url
    initialStartDate: {
      type: String,
      default: dayjs()
        .tz()
        .format("YYYY-MM-DD")
    },
    initialEndDate: {
      type: String,
      default: dayjs()
        .tz()
        .format("YYYY-MM-DD")
    },
    initialPreset: {
      type: String,
      default: ""
    },
    presetsOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update"],
  data() {
    return {
      selectorButtonLabel: "",
      selectedState: {
        preset: "",
        range: {
          from: this.initialStartDate,
          to: this.initialEndDate
        }
      },
      popupState: {
        preset: "",
        range: this.formatPopupRange(this.initialStartDate, this.initialEndDate)
      },
      presets: {
        Today: {
          from: this.$dayjs()
            .tz()
            .format("YYYY-MM-DD"),
          to: this.$dayjs()
            .tz()
            .format("YYYY-MM-DD")
        },
        Yesterday: {
          from: this.$dayjs().tz()
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          to: this.$dayjs().tz()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        "Last 7 Days": {
          from: this.$dayjs().tz()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          to: this.$dayjs().tz()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        "Last 14 Days": {
          from: this.$dayjs().tz()
            .subtract(14, "days")
            .format("YYYY-MM-DD"),
          to: this.$dayjs().tz()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        "Last 30 Days": {
          from: this.$dayjs().tz()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
          to: this.$dayjs().tz()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        "This Week": {
          from: this.$dayjs().tz()
            .startOf("week")
            .format("YYYY-MM-DD"),
          to: this.$dayjs()
            .tz()
            .format("YYYY-MM-DD")
        },
        "Last Week": {
          from: this.$dayjs().tz()
            .subtract(7, "days")
            .startOf("week")
            .format("YYYY-MM-DD"),
          to: this.$dayjs().tz()
            .subtract(7, "days")
            .endOf("week")
            .format("YYYY-MM-DD")
        },
        "This Month": {
          from: this.$dayjs().tz()
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: this.$dayjs()
            .tz()
            .format("YYYY-MM-DD")
        },
        "Last Month": {
          from: this.$dayjs().tz()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: this.$dayjs().tz()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD")
        }
      }
    };
  },
  watch: {
    "popupState.range"() {
      this.refreshPreset("popup");
    }
  },
  mounted() {
    if (
      this.initialPreset.length > 0 &&
      typeof this.presets[this.initialPreset] !== "undefined"
    ) {
      let thisPreset = this.presets[this.initialPreset];
      this.selectedState.range = thisPreset;
      this.popupState.range = this.formatPopupRange(
        thisPreset.from,
        thisPreset.to
      );
    }

    this.refreshPreset("selected");
    this.refreshPreset("popup");
    this.setSelectorButtonLabel();
  },
  methods: {
    refreshPreset(state) {
      let rangeToCompare = null;
      if (state === "popup") {
        if (
          !this.popupState.range ||
          typeof this.popupState.range !== "object"
        ) {
          rangeToCompare = {
            from: this.popupState.range ?? "0",
            to: this.popupState.range ?? "0"
          };
        } else {
          rangeToCompare = this.popupState.range;
        }
      } else {
        rangeToCompare = this.selectedState.range;
      }

      let presetMatchFound = false;
      Object.entries(this.presets).some(preset => {
        let presetLabel = preset[0];
        let presetRange = preset[1];

        if (
          presetRange.from === rangeToCompare.from &&
          presetRange.to === rangeToCompare.to
        ) {
          if (state === "popup") {
            this.popupState.preset = presetLabel;
          } else {
            this.selectedState.preset = presetLabel;
          }
          presetMatchFound = true;
          return true;
        }
      });

      if (!presetMatchFound) {
        if (state === "popup") {
          this.popupState.preset = "";
        } else {
          this.selectedState.preset = "";
        }
      }
    },
    setPopupPreset(presetLabel) {
      this.popupState.preset = presetLabel;
      let thisPresetRange = this.presets[presetLabel];
      this.popupState.range = this.formatPopupRange(
        thisPresetRange.from,
        thisPresetRange.to
      );
    },
    setSelectorButtonLabel() {
      this.selectorButtonLabel =
        this.selectedState.range.from === this.selectedState.range.to
          ? this.$dayjs(this.selectedState.range.to, "YYYY-MM-DD").format("ll")
          : this.$dayjs(this.selectedState.range.from, "YYYY-MM-DD").format(
              "ll"
            ) +
            " - " +
            this.$dayjs(this.selectedState.range.to, "YYYY-MM-DD").format("ll");
    },
    savePopupChanges() {
      if (!this.popupState.range) return;

      if (typeof this.popupState.range !== "object") {
        this.selectedState.range = {
          from: this.popupState.range,
          to: this.popupState.range
        };
      } else {
        this.selectedState.range = this.popupState.range;
      }
      this.selectedState.preset = this.popupState.preset;
      this.setSelectorButtonLabel();
      this.$refs.dateRangeSelectorPopup.hide();
      this.$emit("update", {
        startDate: this.selectedState.range.from,
        endDate: this.selectedState.range.to
      });
    },
    cancelPopupChanges() {
      this.popupState.range = this.formatPopupRange(
        this.selectedState.range.from,
        this.selectedState.range.to
      );
      this.popupState.preset = this.selectedState.preset;
      this.setSelectorButtonLabel();
      this.$refs.dateRangeSelectorPopup.hide();
    },
    onPopupHide() {
      if (
        (typeof this.popupState.range !== "object" &&
          this.popupState.range !== this.selectedState.range.from) ||
        (this.popupState.range.from !== this.selectedState.range.from &&
          this.popupState.range.to !== this.selectedState.range.to)
      ) {
        this.popupState.range = this.formatPopupRange(
          this.selectedState.range.from,
          this.selectedState.range.to
        );
        this.popupState.preset = this.selectedState.preset;
      }
    },
    formatPopupRange(startDate, endDate) {
      if (startDate === endDate) {
        return startDate;
      }
      return {
        from: startDate,
        to: endDate
      };
    }
  }
};
</script>

<style scoped lang="scss"></style>
